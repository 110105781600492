/* You can add global styles to this file, and also import other style files */
@import url("../node_modules/bootstrap/dist/css/bootstrap.css");
@import url("../node_modules/bootstrap-icons/font/bootstrap-icons.css");
@import url("../src/app/Fonts/fonts/stylesheet.css");
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

:root {
  --text-color: #585858;
  --bg-black: #000000;
  --color-white: #ffffff;
  --color-white-100: #f6f6f6;
  --color-white-200: #f5f6fa;
  --color-gray-700: #afafaf;
  --color-blue-500: #0d6efd;
  --color-blue-600: #1a73e8;
  --color-blue-800: #36f;
  --color-blue-900: #253053;
  --color-gray-200: #e6e6e6;
  --color-yellow-dark: #ffaa00;
  --color-lightYello-400: #ffe28c;
  --color-yello-200: #fbbc05;
  --color-yellow-500: #FFF1C2;
  --color-green-600: #008f99;
  --color-grey-300: #797979
  --font-family-primary: "Visby CF, sans-serif";
}

body {
  overflow-x: hidden !important;
  color: var(--text-color) !important;
  transition: all 700ms ease-out;
  will-change: background;
  font-family: "Visby CF", sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--color-white);
  -webkit-text-size-adjust: 100%;
}

h1 {
  font-size: 2.25rem;
}

a {
  color: var(----color-blue-500);
  text-decoration: underline !important;
}

.button-primary {
  background-color: var(--color-blue-600);
  border: 1px solid var(--color-blue-600);
  cursor: pointer;
  border-radius: 5px;
  padding: 12px 30px;
  color: var(--color-white);
  outline: 0;
  font-weight: 700;
  font-size: 1.125rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.button-transparent {
  background: 0 0;
  cursor: pointer;
  border-radius: 5px;
  padding: 17px 20px;
  color: var(--text-color);
  outline: 0;
  border: none;
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 22px;
}

.button-white-bg {
  border: none;
  border-radius: 10px;
  color: var(--color-blue-600);
  font-weight: 700;
  line-height: 28px;
  font-size: 1.125rem;
  padding: 14px 28px;
  white-space: nowrap;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  background-color: #fff;
  outline: 0;
}

.container-fluid {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.column-124 {
  column-gap: 124px;
}

.w-70 {
  width: 70%;
}

// html {
//   scroll-behavior: auto;
// }

.container {
  width: 1170px;
}

.gap26 {
  column-gap: 26px;
}

.meserr {
  //position: relative;
  font-size: 12px !important;
  color: red;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  outline: 0;
  box-shadow: none;
}

// more feature--------------------------------'
.more-features {
  padding: 120px 0px;
  background-color: #fff;
  .g-access {
    padding: 40px 0 80px;
  }
  .more-features-top-content {
    text-align: center;
    margin-bottom: 64px;
    .heading-2 {
      font-size: 36px;
      line-height: 43px;
      margin-bottom: 16px;
      font-weight: 900;
    }
  }

  .feature_text {
    text-decoration: none !important;
    color:var(--text-color);
    .more-features-box {
        background-color: #fff;
        padding: 15px;
        box-shadow: -1px 2px 8px 0px rgba(82, 82, 82, 0.16);
        border: 1px solid #efefef;
        border-radius: 20px;
        text-align: center;
        height: 100%;
        p {
          font-weight: 700;
          font-size: 14px;
          line-height: normal;
          text-align: center;
          color: #aeaeae;
        }
        .heading-4 {
          font-size: 20px;
          line-height: 24px;
          font-weight: 700;
          margin-bottom: 16px;
        }
      }
  }
 
}

input:-internal-autofill-selected
 {
  background-color: transparent !important
 }

 input:-webkit-autofill,
 input:-webkit-autofill:hover, 
 input:-webkit-autofill:focus, 
 input:-webkit-autofill:active,
 input:-internal-autofill-selected,
 input:-internal-autofill-previewed {
     background-color: white !important;
     -webkit-box-shadow: 0 0 0 30px white inset !important;
 }
  // media query-----------
@media (max-width: 991px) and (min-width: 340px) {

  .content-box{
text-align: center;
margin-top: 40px;
  }
  .retrieval-as-easy {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
}




// @media screen and (max-width: 1440px) {
//   h1, h3, h2 {
//     font-size: 1.75rem !important;
//   }

//   p, button, li, .common-para2, .common-para, a {
//     font-size: 1rem !important;
//   }
// }

// @media screen and (max-width: 1080px) {
//   .common-para {
//     font-size: 0.75rem;
//   }
// }

@media (max-width: 767px) and (min-width: 340px) {
  [data-aos=fade-left], [data-aos=fade-right], [data-aos=fade-up] {
    transform: translate3d(0,0,0)!important;
  }
}

@media (max-width: 767px) {
  .underline-highlight{
    &::before {
        display: none;
    }

}
}
